<template>
<div>
  <md-card class="md-primary" md-theme="black-card">
    <md-card-header>
      <md-card-header-text>
        <div class="md-title">{{data_user.email}}</div>
        <div class="md-subhead">Subtitle here</div>
      </md-card-header-text>
      <md-card-media>
      </md-card-media>
    </md-card-header>
  </md-card>
</div>
</template>
<script>

/* eslint-disable */
export default {
  name: 'Profile',
  components: {
    upload: () => import('../components/Upload.vue'),
  },
  created () {
    this.data_user = this.get_data_storage()
  },
  data() {
    return {      
      data_user: {},
      url: 'registers/'
    };
  },
  methods: {
    update_avatar(avatar_id) {
      let payload = { }
      payload.change_avatar = 'True'
      payload.id = this.settings_obj.student
      payload.avatar = avatar_id
      const self = this;
      this.putRequest(payload).then((data) => {
        self.$store.dispatch('set_avatar_url', { avatarUrl: data.data.avatar_url });
        self.$store.dispatch('set_avatar_id', { avatarId: data.data.avatar_id });
        self.save_storage('settings', this.settings_obj);        
        self.$swal.fire('Actualizado', 'Actualización exitosa.', 'success');
      }).catch((error) =>{
        console.log(error);
        self.$swal.fire('Error!', 'Ha fallado la actualización.', 'error');
      });
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    get_data_storage() {
      if (JSON.parse(localStorage.getItem('user'))) {
        return JSON.parse(localStorage.getItem('user'));
      }
      return null;
    },
    async putRequest(payload) {
      const data = await this.$http.put(`users/${payload.id}/`, payload);
      return data;
    },
    async sendRequest(payload) {
      const result = await this.$http.get(this.url, { params: payload });
      return result;
    },
  },
  computed: {
  },
};

</script>
